@use '@royalaholddelhaize/design-system-pantry-web/utils/mq.scss' as mq;

.root {
    position: relative;
    display: flex;
}

.title {
    margin-bottom: var(--spacing-core-2);
}

.titleSkeleton {
    height: var(--sizing-core-10);
}

.subtitleSkeleton {
    height: var(--sizing-core-5);
}

.sectionGrid {
    width: 100%;
}
